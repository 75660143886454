import { MouseEventHandler } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { sendPathname } from 'quotient/utils/iframeUtils';
import { getElementText, isSpaEnabled } from 'quotient/utils/utils';

type CiqLinkProps = {
  href: string;
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  ['data-testid']?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openInNewTab?: boolean;
  /*
    unmask = true to reveal button text for analytics. Use this setting for regular buttons
    unmask = false to leave button text hidden for analytics. Use this setting if your button has user generated text
  */
  unmask: boolean;
  id?: string;
};

export const CiqQuotientLink = ({
  href,
  children,
  className,
  testId,
  'data-testid': dataTestId,
  onClick,
  openInNewTab,
  unmask,
  ...otherProps
}: CiqLinkProps) => {
  const childrenText = getElementText(children);

  const onClickHandler: MouseEventHandler<HTMLElement> = (event) => {
    onClick?.(event);
    sendPathname(href);
  };
  const isMailToLink = href?.startsWith('mailto:');
  return isSpaEnabled() && !isMailToLink ? (
    <Link
      {...otherProps}
      className={className}
      data-testid={`${testId || dataTestId || children}-href`}
      data-text={unmask && childrenText ? childrenText : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
      target={openInNewTab ? '_blank' : undefined}
      to={href}
      onClick={onClickHandler}
    >
      {children}
    </Link>
  ) : (
    <a
      {...otherProps}
      className={className}
      data-testid={`${testId || dataTestId || children}-href`}
      data-text={unmask && childrenText ? childrenText : undefined}
      href={href}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClickHandler}
    >
      {children}
    </a>
  );
};
