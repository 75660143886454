import type { ComponentStyleConfig } from '@chakra-ui/theme';

const getOutlineStyle = (colorFamily: string) => {
  return {
    color: `${colorFamily}.400`,
    border: '1px solid',
    borderColor: `${colorFamily}.400`,
    bg: 'white',
    _hover: {
      color: 'white',
      bg: `${colorFamily}.400`,
      _disabled: {
        bg: 'white',
        color: `${colorFamily}.400`,
      },
    },
    _active: {
      color: 'white',
      bg: `${colorFamily}.600`,
      borderColor: `${colorFamily}.600`,
    },
    _focusVisible: {
      outlineColor: `${colorFamily}.100`,
    },
  };
};

const getFilledStyle = (colorFamily: string) => {
  return {
    color: 'white',
    bg: `${colorFamily}.400`,
    _hover: {
      color: 'white',
      bg: `${colorFamily}.600`,
      _disabled: {
        bg: `${colorFamily}.400`,
      },
    },
    _active: {
      bg: `${colorFamily}.800`,
    },
    _focusVisible: {
      bg: `${colorFamily}.400`,
      outlineColor: `${colorFamily}.100`,
    },
  };
};

const getGhostStyle = (colorFamily: string) => {
  return {
    color: `${colorFamily}.400`,
    border: 'none',
    bg: 'transparent',
    _hover: {
      color: `${colorFamily}.600`,
      bg: 'transparent',
      _disabled: {
        color: `gray.100`,
      },
    },
    _active: {
      color: `${colorFamily}.400`,
    },
    _focusVisible: {
      outlineColor: `${colorFamily}.100`,
    },
  };
};

const getIconOnlyStyles = (colorFamily: string, withCircle = false) => {
  return {
    borderRadius: 'full',
    color: `${colorFamily}.400`,
    bg: 'transparent',
    border: `${withCircle ? '1px solid' : '0'}`,
    borderColor: 'gray.400',
    _hover: {
      color: `${withCircle ? 'white' : `${colorFamily}.600`}`,
      bg: `${withCircle ? 'gray.400' : 'transparent'}`,
      _disabled: {
        color: 'gray.400',
      },
    },
    _disabled: {
      color: 'gray.400',
    },
    _active: {
      color: `${colorFamily}.800`,
    },
    _focusVisible: {
      color: `${colorFamily}.600`,
    },
  };
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focusVisible: {
      outlineOffset: 0,
      outlineWidth: 4,
    },
    _disabled: {
      opacity: 0.5,
      cursor: 'default',
    },
  },
  variants: {
    primary: getFilledStyle('primary'),
    'outline-primary': getOutlineStyle('primary'),
    secondary: getFilledStyle('gray'),
    'outline-secondary': getOutlineStyle('gray'),
    info: getFilledStyle('info'),
    'outline-info': getOutlineStyle('info'),
    danger: getFilledStyle('danger'),
    'outline-danger': getOutlineStyle('danger'),
    'add-another': getGhostStyle('primary'),
    ghost: getGhostStyle('primary'),
    'icon-only-primary': getIconOnlyStyles('primary'),
    'icon-only-danger': getIconOnlyStyles('danger'),
    'icon-only-default': getIconOnlyStyles('gray'),
    link: {
      color: 'primary.400',
      bg: 'white',
      padding: 0,
      border: 0,
      '&:hover': {
        color: 'primary.600',
      },
      '&:focus': {
        color: 'primary.800',
        border: 0,
        outline: 0,
        boxShadow: 'none !important',
      },
      '&:active': {
        color: 'primary.800',
        border: 0,
        outline: 0,
        boxShadow: 'none !important',
      },
    },
    // Icon only variants
    add: getIconOnlyStyles('primary'),
    remove: getIconOnlyStyles('danger'),
    edit: getIconOnlyStyles('primary'),
    'export--non-circle': getIconOnlyStyles('primary'),
    'actions-arrow': getIconOnlyStyles('gray'),
    'expand-down-arrow': getIconOnlyStyles('gray'),
    'arrow-left': getIconOnlyStyles('gray', true),
    'arrow-right': getIconOnlyStyles('gray', true),
    import: getIconOnlyStyles('gray', true),
    export: getIconOnlyStyles('gray', true),
    'user-plus': getIconOnlyStyles('gray', true),
    'link-icon': getIconOnlyStyles('gray', true),
    sitemap: getIconOnlyStyles('gray', true),
    'kb-circle': getIconOnlyStyles('gray', true),
    payout: getIconOnlyStyles('primary'),
    spreadsheet: getIconOnlyStyles('primary'),
    'tab-export': getIconOnlyStyles('gray'),
    'batch-column-editor': getIconOnlyStyles('gray'),
    'grid-select': getIconOnlyStyles('gray', true),
    'list-select': getIconOnlyStyles('gray', true),
    'close--non-circle': getIconOnlyStyles('gray'),
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
    iconSpacing: 0.5,
  },
};
