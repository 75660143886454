import { extendTheme } from 'quotient';

import { AlertStyle as Alert } from './components/Alert/Alert';
import { AvatarStyle as Avatar } from './components/Avatar/Avatar';
import { BadgeStyle as Badge } from './components/Badge/Badge';
import { ButtonStyles } from './components/Buttons/Button';
import { CheckboxStyle as Checkbox } from './components/Checkbox/Checkbox';
import { Drawer } from './components/Drawer/drawer';
import { FormLabelStyle as FormLabel, FormStyle as Form } from './components/Field';
import { Flow } from './components/Flow/flow';
import { IconButtonStyles } from './components/IconButton/IconButton';
import { InputStyle as Input } from './components/Input/Input';
import { Link } from './components/Link/link';
import { Menu } from './components/Menu/menu';
import { Modal } from './components/Modal/modal';
import { Popover } from './components/Popover/popover';
import { RadioStyle as Radio } from './components/Radio/Radio';
import { SelectStyle as Select } from './components/Select/Select';
import { SwitchStyle as Switch } from './components/Switch/Switch';
import { TabsStyle as Tabs } from './components/Tabs/Tabs';
import { WorksheetTabStyle as WorksheetTab } from './components/Tabs/worksheetTab';
import { TagStyle as Tag } from './components/Tag/Tag';
import { ToastStyle as Toast } from './components/Toast/useToast';
import { TooltipStyle as Tooltip } from './components/Tooltip/Tooltip';
import { borders } from './foundations/borders/borders';
import { breakpoints } from './foundations/breakpoints/breakpoints';
import { colors, semanticColors } from './foundations/colors/colors';
import { shadows } from './foundations/shadows/shadows';
import { space } from './foundations/spacing/spacing';
import { fonts, fontSizes, letterSpacings, lineHeights, textStyles } from './foundations/typography/typography';

import { Button } from '../chakraStyles/buttons';

const overrides = {
  initialColorMode: 'system',
  useSystemColorMode: false,
  styles: {
    global: {
      body: {
        fontFamily: '',
        color: '',
        bg: '',
        transitionProperty: '',
        transitionDuration: '',
        lineHeight: '',
      },
      '*::placeholder': {
        color: '',
      },
      '*, *::before, &::after': {
        borderColor: '',
        wordWrap: '',
      },
    },
  },
  // keep these alphabetized
  components: {
    Alert,
    Avatar,
    Badge,
    Button,
    QuotientButton: ButtonStyles,
    QuotientIconButton: IconButtonStyles,
    Checkbox,
    Drawer,
    Flow, // TODO: delete this  when we completely move to WorkflowsV2
    Form,
    FormLabel,
    Input,
    Link,
    Menu,
    Modal,
    Popover,
    Radio,
    Select,
    Switch,
    Tabs,
    Tag,
    Toast,
    Tooltip,
    WorksheetTab,
  },
  lineHeights,
  fonts,
  fontSizes,
  letterSpacings,
  textStyles,
  colors,
  semanticTokens: {
    colors: semanticColors,
  },
  shadows,
  space,
  borders,
  breakpoints,
};

export default extendTheme(overrides);
