import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import debounce from 'lodash/debounce';
import { RefObject, useRef, useState } from 'react';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

import { QuotientInput } from '../Input/Input';

export type QuotientSearchProps = {
  isDisabled?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  externalRef?: RefObject<HTMLInputElement>;
  onSearch: (value: string) => void;
} & SelectedStyledSystemProps &
  CommonCIQProps;

const search = (onSearch: (value: string) => void, value: string) => onSearch(value);

const debouncedSearch = debounce(search, 125);

export const QuotientSearch = ({
  isDisabled,
  isInvalid,
  placeholder,
  externalRef,
  onSearch,
  ...rest
}: QuotientSearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const textRef = externalRef || searchRef;
  const [searchValue, setSearchValue] = useState('');

  return (
    <QuotientInput
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      leftIcon={faMagnifyingGlass}
      placeholder={placeholder}
      ref={textRef}
      rightIcon={(searchValue.length > 0 || textRef?.current?.value) && !isDisabled ? faXmark : undefined}
      type="search"
      onChange={(e) => {
        const value = e.target.value;
        setSearchValue(value);
        debouncedSearch(onSearch, value);
      }}
      onRightIconClick={() => {
        setSearchValue('');
        if (textRef.current) {
          textRef.current.value = '';
        }
        onSearch('');
      }}
      {...rest}
    />
  );
};
