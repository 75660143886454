export const borders = {
  lightBg: {
    border: '1px solid',
    borderColor: 'primaryNeutral.400',
  },
  darkBg: {
    border: '1px solid',
    borderColor: 'primaryNeutral.600',
  },
};
