import { useContext, createContext, useState, SetStateAction, Dispatch } from 'react';

import { JobsAggregationQueryJobDataType, JobsAggregationStatus, JobsService } from 'services/jobs';
import { StringifiedUUID } from 'types';
import { APIError } from 'utils/errors';

export type JobsAggregationContextType = {
  jobsAggregationId: StringifiedUUID;
  setJobsAggregationId: Dispatch<SetStateAction<StringifiedUUID>>;
  pollJobsAggregationQuery: (jobId: StringifiedUUID) => void;
  jobsAggregationData: Array<JobsAggregationQueryJobDataType>;
  setJobsAggregationData: Dispatch<SetStateAction<Array<JobsAggregationQueryJobDataType>>>;
  jobsStatus: JobsStatus;
  setJobsPendingState: (jobId: StringifiedUUID) => void;
  setJobsNotStartedState: () => void;
  setJobsStatus: Dispatch<SetStateAction<JobsStatus>>;
};

// TODO: dedupe with types/jobs.ts once confirming direction with jesse
export enum JobsStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  NOT_STARTED = 'NOT_STARTED',
}

const JobsAggregationContext = createContext<JobsAggregationContextType>({} as JobsAggregationContextType);
export const useJobsAggregationContext = () => useContext(JobsAggregationContext);
const MAX_POLLING_COUNT = 15;

export const JobsAggregationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [jobsAggregationId, setJobsAggregationId] = useState<StringifiedUUID>('');
  const [jobsAggregationData, setJobsAggregationData] = useState<Array<JobsAggregationQueryJobDataType>>([]);
  const [jobsStatus, setJobsStatus] = useState<JobsStatus>(JobsStatus.NOT_STARTED);

  const setJobsPendingState = (jobId: StringifiedUUID): void => {
    setJobsStatus(JobsStatus.PENDING);
    setJobsAggregationData([]);
    setJobsAggregationId(jobId);
  };

  const setJobsNotStartedState = (): void => {
    setJobsStatus(JobsStatus.NOT_STARTED);
    setJobsAggregationId('');
    setJobsAggregationData([]);
  };

  const setJobsFailureState = (): void => {
    setJobsStatus(JobsStatus.FAILURE);
    setJobsAggregationData([]);
    setJobsAggregationId('');
  };

  const setJobsSuccessState = (data: Array<JobsAggregationQueryJobDataType>): void => {
    setJobsStatus(JobsStatus.SUCCESS);
    setJobsAggregationData(data);
  };

  const pollJobsAggregationQuery = (jobId: StringifiedUUID) => {
    let count = 0;
    setJobsPendingState(jobId);
    const intervalRef = setInterval(
      async () => {
        if (count >= MAX_POLLING_COUNT) {
          clearInterval(intervalRef);
          setJobsFailureState();
          throw new APIError({
            type: 'api_error',
            message: 'Failed to retrieve job results',
            status: 500,
          });
        }
        try {
          const data = await JobsService.getJobsAggregationQuery(jobId);
          if (data.status !== JobsAggregationStatus.SUCCESS) {
            count += 1;
            return;
          }
          setJobsSuccessState(data.data);
          clearInterval(intervalRef);
        } catch (e) {
          setJobsFailureState();
          clearInterval(intervalRef);
        }
      },
      1000,
      [jobId],
    );
  };

  return (
    <JobsAggregationContext.Provider
      value={{
        jobsAggregationId,
        pollJobsAggregationQuery,
        setJobsAggregationId,
        jobsAggregationData,
        setJobsAggregationData,
        jobsStatus,
        setJobsPendingState,
        setJobsStatus,
        setJobsNotStartedState,
      }}
    >
      {children}
    </JobsAggregationContext.Provider>
  );
};
