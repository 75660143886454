import { Flex } from '@chakra-ui/react';
import React from 'react';

import { QuotientCheckboxProps } from '../Checkbox/Checkbox';
import { Field, FieldProps } from '../Field';

type CheckboxGroupProps = {
  children: React.ReactElement<QuotientCheckboxProps>[];
};

type CheckboxGroupFieldProps = CheckboxGroupProps & FieldProps;

const CheckboxGroup = ({ children }: CheckboxGroupProps) => {
  return (
    <Flex direction="column" gap="16px" mt="12px">
      {children}
    </Flex>
  );
};

export const QuotientCheckboxGroupField = ({
  id,
  label,
  description,
  isRequired,
  showOptional,
  isInvalid,
  errorMessage,
  children,
  ...rest
}: CheckboxGroupFieldProps) => {
  return (
    <Field
      description={description}
      errorMessage={errorMessage}
      id={id}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      showOptional={showOptional}
      {...rest}
    >
      <CheckboxGroup>{children}</CheckboxGroup>
    </Field>
  );
};
