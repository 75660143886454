import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';

import { deleteRequest, getRequest, postRequest, putRequest } from 'services/apiRequests';
import { APIList, StringifiedUUID } from 'types';
import { TerritoryDataObject } from 'types/tqModelTree';
import type { APIError } from 'utils/errors';

const apiUrl = '/api/v1/tq/hierarchy-models';

export type UpdateTerritoryDataObjectRequest =
  | Pick<TerritoryDataObject, 'name'>
  | {
      parent_id: string;
      position: number;
    };

export type CreateTerritoryDataObjectRequest =
  | Pick<TerritoryDataObject, 'name' | 'external_id'>
  | {
      parent_id: string;
      object_type: 'territory';
    };

type UpdateTerritoryDataObjectProps = {
  hierarchyModelId: StringifiedUUID;
  dataObjectId: StringifiedUUID;
  payload: UpdateTerritoryDataObjectRequest;
};

type CreateTerritoryDataObjectProps = {
  hierarchyModelId: StringifiedUUID;
  payload: CreateTerritoryDataObjectRequest;
};

type DeleteTerritoryDataObjectProps = Pick<UpdateTerritoryDataObjectProps, 'hierarchyModelId' | 'dataObjectId'>;

const updateTerritoryDataObject = (csrfToken: string) => ({
  hierarchyModelId,
  dataObjectId,
  payload,
}: UpdateTerritoryDataObjectProps) => {
  return putRequest<UpdateTerritoryDataObjectRequest, TerritoryDataObject>(
    `${apiUrl}/${hierarchyModelId}/objects/${dataObjectId}/`,
    payload,
    csrfToken,
  );
};

const createTerritoryDataObject = (csrfToken: string) => ({
  hierarchyModelId,
  payload,
}: CreateTerritoryDataObjectProps) => {
  return postRequest<CreateTerritoryDataObjectRequest, TerritoryDataObject>(
    `${apiUrl}/${hierarchyModelId}/objects/`,
    payload,
    csrfToken,
  );
};

const deleteDataObject = (csrfToken: string) => ({
  hierarchyModelId,
  dataObjectId,
}: DeleteTerritoryDataObjectProps) => {
  return deleteRequest<null, null>(`${apiUrl}/${hierarchyModelId}/objects/${dataObjectId}`, null, csrfToken);
};

const getTerritoryDataObjects = (modelId: StringifiedUUID, limit?: number, filterQuery?: string) => {
  let queryParams = '';
  if (limit && filterQuery) {
    queryParams = `?limit=${limit}&q=${filterQuery}`;
  } else {
    queryParams = `${limit ? `?limit=${limit}` : ''}${filterQuery ? `?q=${filterQuery}` : ''}`;
  }

  return getRequest<null, APIList<TerritoryDataObject>>(`${apiUrl}/${modelId}/objects/${queryParams}`);
};

const useGetTerritoryDataObjectsQuery = (modelId: StringifiedUUID, limit?: number, filterQuery?: string) => {
  const QUERY_KEY = ['hierarchy-models', modelId, 'territory-objects'];
  if (filterQuery) {
    QUERY_KEY.push(filterQuery);
  }

  const queryClient = useQueryClient();

  const query = useQuery<APIList<TerritoryDataObject>, APIError>(QUERY_KEY, () =>
    getTerritoryDataObjects(modelId, limit, filterQuery),
  );
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);
  return { query, invalidateQuery };
};

const useCreateTerritoryDataObjectMutation = (
  csrfToken: string,
  options: UseMutationOptions<TerritoryDataObject, APIError, CreateTerritoryDataObjectProps> = {},
) => {
  return {
    mutation: useMutation<TerritoryDataObject, APIError, CreateTerritoryDataObjectProps>(
      createTerritoryDataObject(csrfToken),
      options,
    ),
  };
};

const useUpdateTerritoryDataObjectMutation = (
  csrfToken: string,
  options: UseMutationOptions<TerritoryDataObject, APIError, UpdateTerritoryDataObjectProps> = {},
) => {
  return {
    mutation: useMutation<TerritoryDataObject, APIError, UpdateTerritoryDataObjectProps>(
      updateTerritoryDataObject(csrfToken),
      options,
    ),
  };
};

const useDeleteTerritoryDataObjectMutation = (
  csrfToken: string,
  options: UseMutationOptions<null, APIError, DeleteTerritoryDataObjectProps> = {},
) => {
  return {
    mutation: useMutation<null, APIError, DeleteTerritoryDataObjectProps>(deleteDataObject(csrfToken), options),
  };
};

export const TerritoryDataObjectsService = {
  apiUrl,
  getTerritoryDataObjects,
  useGetTerritoryDataObjectsQuery,
  useUpdateTerritoryDataObjectMutation,
  useDeleteTerritoryDataObjectMutation,
  useCreateTerritoryDataObjectMutation,
};
