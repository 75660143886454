import {
  AlertStatus,
  Box,
  Flex,
  ToastId,
  useChakra,
  toast,
  UseToastOptions,
  CreateStandAloneToastParam,
  RenderProps,
  ColorModeContext,
  ThemeProvider,
  ToastPosition,
  ToastPositionWithLogical,
  useMultiStyleConfig,
  Text,
} from '@chakra-ui/react';
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faXmark,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import defaultTheme, { ComponentMultiStyleConfig } from '@chakra-ui/theme';
import { QuotientIcon } from '../Icon/Icon';
import { QuotientIconButton } from '../IconButton/IconButton';
import { useLatestRef } from '@chakra-ui/hooks';
import { ReactNode, useMemo } from 'react';

export const ToastStyle: ComponentMultiStyleConfig = {
  parts: ['container', 'icon'],
  baseStyle: {
    container: {
      minWidth: 'auto',
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '4px',
      padding: '16px',
      gap: '12px',
      justifyContent: 'space-between',
      alignItems: 'center',
      shadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
    },
  },
  variants: {
    success: {
      container: {
        backgroundColor: 'positive.50',
      },
      icon: {
        color: 'positive.600',
      },
    },
    error: {
      container: {
        backgroundColor: 'danger.50',
      },
      icon: {
        color: 'danger.600',
      },
    },
    warning: {
      container: {
        backgroundColor: 'warning.50',
      },
      icon: {
        color: 'warning.600',
      },
    },
    info: {
      container: {
        backgroundColor: 'primary.100',
      },
      icon: {
        color: 'primary.600',
      },
    },
  },
};

export const iconMapping: Record<AlertStatus, IconDefinition> = {
  error: faCircleExclamation,
  success: faCheckCircle,
  warning: faCircleExclamation,
  info: faCircleInfo,
};

export type ToastProp = {
  status?: AlertStatus;
  id?: ToastId;
  title?: ReactNode;
  isClosable?: boolean;
  onClose?: () => void;
  description?: ReactNode;
};

export const Toast = ({ status = 'success', id, title, isClosable, onClose, description }: ToastProp) => {
  const styles = useMultiStyleConfig('Toast', { variant: status });
  const alertTitleId = typeof id !== 'undefined' ? `toast-${id}-title` : undefined;
  return (
    <Flex __css={styles.container}>
      <QuotientIcon
        alignSelf="flex-start"
        color={(styles?.icon?.color as string) || 'positive.600'}
        icon={iconMapping[status]}
        mt={1}
      />
      <Box>
        {title ? (
          <Text id={alertTitleId} m={0} textStyle="bodySemiBold">
            {title}
          </Text>
        ) : null}
        {description ? (
          <Text m={0} textStyle="bodyRegular">
            {description}
          </Text>
        ) : null}
      </Box>
      {isClosable ? (
        <Box alignSelf="flex-start" mt={-2}>
          <QuotientIconButton icon={faXmark} unmask onClick={onClose} />
        </Box>
      ) : null}
    </Flex>
  );
};

const defaults = {
  duration: 5000,
  isClosable: true,
  variant: 'subtle',
  position: 'top-right',
  containerStyle: {
    minWidth: 'auto',
  },
} as const;

//  START - COPY PASTED CODE FROM @chakra-ui to make customization work in Quotient for chakra 1.8.4
export const defaultStandaloneParam: Required<CreateStandAloneToastParam> = {
  theme: defaultTheme,
  colorMode: 'light',
  toggleColorMode: () => {},
  setColorMode: () => {},
  defaultOptions: defaults,
};
export function getToastPlacement(
  position: ToastPositionWithLogical | undefined,
  dir: 'ltr' | 'rtl',
): ToastPosition | undefined {
  if (!position) return;
  const logicals: any = {
    'top-start': { ltr: 'top-left', rtl: 'top-right' },
    'top-end': { ltr: 'top-right', rtl: 'top-left' },
    'bottom-start': { ltr: 'bottom-left', rtl: 'bottom-right' },
    'bottom-end': { ltr: 'bottom-right', rtl: 'bottom-left' },
  };

  const logical = logicals[position as keyof typeof logicals];
  return logical?.[dir] ?? position;
}

export type IToast = UseToastOptions;
export type UseToastOptionsNormalized = Omit<UseToastOptions, 'position'> & {
  position?: ToastPosition;
};
/**
 * Create a toast from outside of React Components
 */
export function createStandaloneToast({
  theme = defaultStandaloneParam.theme,
  colorMode = defaultStandaloneParam.colorMode,
  toggleColorMode = defaultStandaloneParam.toggleColorMode,
  setColorMode = defaultStandaloneParam.setColorMode,
  defaultOptions = defaultStandaloneParam.defaultOptions,
}: CreateStandAloneToastParam = defaultStandaloneParam) {
  const renderWithProviders = (props: React.PropsWithChildren<RenderProps>, options: UseToastOptionsNormalized) => (
    <ThemeProvider theme={theme}>
      <ColorModeContext.Provider value={{ colorMode, setColorMode, toggleColorMode }}>
        <Toast {...props} {...options} />
      </ColorModeContext.Provider>
    </ThemeProvider>
  );

  const toastImpl = (options?: UseToastOptions) => {
    const opts = { ...defaultOptions, ...options } as UseToastOptionsNormalized;
    opts.position = getToastPlacement(opts.position, theme.direction);

    const Message: React.FC<RenderProps> = (props) => renderWithProviders(props, opts);

    return toast.notify(Message, opts);
  };

  toastImpl.close = toast.close;
  toastImpl.closeAll = toast.closeAll;

  // toasts can only be updated if they have a valid id
  toastImpl.update = (id: ToastId, options: Omit<UseToastOptions, 'id'>) => {
    if (!id) return;

    const opts = { ...defaultOptions, ...options } as UseToastOptionsNormalized;
    opts.position = getToastPlacement(opts.position, theme.direction);

    toast.update(id, {
      ...opts,
      message: (props) => renderWithProviders(props, opts),
    });
  };

  toastImpl.isActive = toast.isActive;

  return toastImpl;
}

//  END - COPY PASTED CODE FROM @chakra-ui to make it work in Quotient

export const useQuotientToast = (options?: UseToastOptions) => {
  const { theme, setColorMode, toggleColorMode, colorMode } = useChakra();

  const toastOptions = useLatestRef(options);

  return useMemo(() => {
    return createStandaloneToast({
      theme,
      colorMode,
      setColorMode,
      toggleColorMode,
      defaultOptions: { ...defaults, ...toastOptions.current },
    });
  }, [theme, setColorMode, toggleColorMode, colorMode, toastOptions]);
};
