// TODO: delete this file when we completely move to WorkflowsV2
import { ComponentSingleStyleConfig } from 'quotient';
import { textStyles } from 'quotient/theme/foundations/typography/typography';

export const WORKFLOW_NODE_WIDTH = 280;
export const WORKFLOW_NODE_MIN_HEIGHT = 40;

const miniMapBaseStyle = {
  borderColor: 'secondaryNeutral.400',
  borderWidth: '1px',
};

const nodeBaseStyle = {
  alignItems: 'center',
  bg: 'primaryNeutral.white',
  borderColor: 'borderNeutralPrimaryMediumContrast',
  borderWidth: '1px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  minHeight: `${WORKFLOW_NODE_MIN_HEIGHT}px`,
  width: `${WORKFLOW_NODE_WIDTH}px`,
  textStyle: 'bodySemiBold',
};

const joinNodeStyle = {
  backgroundColor: 'surfaceBackgroundMediumContrast',
  color: 'textMediumContrast',
  border: '1px dashed borderNeutralPrimaryMediumContrast',
  borderStyle: 'dashed',
  textStyle: 'monospaceRegular',
};

const outputNodeStyle = {
  bg: 'secondaryNeutral.200',
  color: 'textHighContrast',
  textStyle: 'monospaceRegular',
};

const nodeStyles = {
  '.react-flow__node': nodeBaseStyle,
  '.react-flow__node-joinNode': joinNodeStyle,
  '.react-flow__node-output': outputNodeStyle,
};

const edgePathStyle = {
  stroke: 'borderNeutralPrimaryMediumContrast',
};

const edgeTextStyle = {
  color: 'textHighContrast',
  textTransform: 'uppercase',
  ...textStyles.monospaceRegular,
  fontSize: 'xs',
};

const edgeTextBackgroundStyle = {
  py: '2px',
  px: 2,
  rx: '4px',
  ry: '4px',
};

const edgeStyles = {
  '.react-flow__edge-path': edgePathStyle,
  '.react-flow__edge-text': edgeTextStyle,
  '.react-flow__edge-textbg': edgeTextBackgroundStyle,
};

export const Flow: ComponentSingleStyleConfig = {
  baseStyle: {
    bgColor: 'secondaryNeutral.50',
    height: 'inherit',
    width: 'inherit',
    '.react-flow__minimap': miniMapBaseStyle,
    ...nodeStyles,
    ...edgeStyles,
  },
};
