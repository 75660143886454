import { faArrowAltCircleUp, faMessage } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import { Flex, Button, Textarea, InputGroup } from 'quotient';

import { CommentHeader } from './ThreadBoxComments';
import { Author } from './ThreadContainer';

export type ThreadInputFieldProps = {
  currentUser: Author;
  onSave: (newComment: string) => void;
  onCancel: () => void;
  initialComment?: boolean;
  initialCommentText?: string;
  hideCommentHeader?: boolean;
  isSubmitDisabled?: boolean;
  disableAutoFocus?: boolean;
  alert?: JSX.Element;
};

export const ThreadInputField: React.VFC<ThreadInputFieldProps> = ({
  currentUser,
  hideCommentHeader,
  initialComment,
  onSave,
  onCancel,
  initialCommentText,
  isSubmitDisabled,
  disableAutoFocus,
  alert,
}) => {
  const [comment, setComment] = useState<string>('');

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSave(comment);
    }
  };

  const inputStyles = `
    .comment-input input {
        border: none;
        outline: none;
  }`;

  const _initialCommentText = initialCommentText || 'Leave comment';
  const _isSubmitDisabled = isSubmitDisabled ?? comment.length === 0;

  return (
    <>
      {!hideCommentHeader && <CommentHeader avatarURL={currentUser?.avatarURL} name={currentUser.name} />}
      <style>{inputStyles}</style>
      <InputGroup size="sm" width="100%">
        <Textarea
          autoFocus={!disableAutoFocus}
          border={0}
          className="comment-input"
          data-testid="comment-input"
          focusBorderColor="transparent"
          marginTop="0.5em"
          minH="36px"
          placeholder={initialComment ? 'Comment...' : 'Add comment'}
          resize="none"
          value={comment}
          onChange={(event) => setComment(event.currentTarget.value)}
          onKeyPress={onKeyPress}
        />
      </InputGroup>
      {alert}
      <Flex direction="row" mt="0.5em">
        <Button size="sm" unmask variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          isDisabled={_isSubmitDisabled}
          leftIcon={initialComment ? faMessage : faArrowAltCircleUp}
          ml="auto"
          size="sm"
          unmask
          variant="solid"
          onClick={() => onSave(comment)}
        >
          {initialComment ? _initialCommentText : 'Add reply'}
        </Button>
      </Flex>
    </>
  );
};
