import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const DRAWER_WIDTH = '448px';

export const Drawer: ComponentStyleConfig = {
  baseStyle: {
    header: {
      paddingInlineEnd: 12,
    },
  },
  variants: {
    copilot: {
      dialog: {
        maxW: DRAWER_WIDTH,
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
    pointersDisabled: {
      dialog: {
        maxW: DRAWER_WIDTH,
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
};
